




















import Generator from '@/components/Generator.vue'

export default {
  components: {
    Generator
  }
}
