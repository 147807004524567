


























































































import Component from 'vue-class-component'
import Vue from 'vue'

const LETTERS_UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const LETTERS_LOWERCASE = LETTERS_UPPERCASE.toLowerCase()
const NUMBERS = '0123456789'
const SPECIAL = '^$%&/()[]{}?+*~#-.,;:_'

@Component
export default class Generator extends Vue {
  // input
  randomData: number[] = []
  lastSize = 0

  // options
  length = 32
  uppercaseLetters = true
  lowercaseLetters = true
  numbers = true
  special = true
  specialChars = SPECIAL
  availableChars: string[] = []

  // output
  generating = false
  rawPassphrase: number[] = []
  passphrase = '00000000000000000000000000000000'
  fontSize = '16px'

  bgcolor = 'hsl(20, 100%, 40%)'

  mounted (): void {
    this.optionsChanged()

    for (let i = 0; i < 100; i++) {
      this.addRandom(Math.random() * i)
    }

    window.addEventListener('resize', e => this.resize())
    window.addEventListener('mousemove', e => this.mouseMove(e))
    window.addEventListener('click', e => this.mouseClick(e))
    window.addEventListener('keyup', e => this.keyUp(e))
    window.addEventListener('devicemotion', e => this.deviceMotion(e))

    this.resize()

    setInterval(() => this.generateString(), 1000)
  }

  optionsChanged (): void {
    this.availableChars = []
    if (this.uppercaseLetters) {
      this.availableChars.push(...LETTERS_UPPERCASE.split(''))
    }
    if (this.lowercaseLetters) {
      this.availableChars.push(...LETTERS_LOWERCASE.split(''))
    }
    if (this.numbers) {
      this.availableChars.push(...NUMBERS.split(''))
    }
    if (this.special) {
      this.availableChars.push(...this.specialChars.split(''))
    }
    this.availableChars.sort(function () {
      return 0.5 - Math.random()
    })

    this.generateString()
  }

  ///

  generateString (): void {
    if (this.generating) return
    // if (this.randomData.length > this.randomDataBuffer.byteLength) return

    if (this.lastSize === this.randomData.length) return
    this.lastSize = this.randomData.length

    const strength = ((this.randomData.length - 100) * 0.05) + (this.length)
    const clr = Math.max(0, Math.min(strength, 120))
    this.bgcolor = `hsl(${clr}, 100%, 40%)`

    this.generating = true

    this.availableChars.sort(function () {
      return 0.5 - Math.random()
    })

    this.rawPassphrase = []
    this.rawPassphrase.fill(Date.now())

    for (let i = 0; i < this.randomData.length; i++) {
      this.rawPassphrase[i % this.length] ^= this.randomData[i]
    }

    this.passphrase = this.rawPassphrase
      .map(v => this.availableChars[v % this.availableChars.length])
      .join('')
    this.generating = false

    // this.fontSize = this.getFontSize(this.passphrase.length)
  }

  copyToClipboard (event: MouseEvent): void {
    const input = event.target as HTMLInputElement
    input.select()
    document.execCommand('copy')
  }

  ///

  addRandom (r: number | string | null | undefined): void {
    if (typeof r === 'string') {
      for (let i = 0; i < r.length; i++) {
        this.addRandom(r.charCodeAt(i))
      }
    } else {
      this.randomData.push(Math.round((r || Math.random()) * Math.random()))
    }
  }

  mouseMove (event: MouseEvent): void {
    this.addRandom(event.x)
    this.addRandom(event.y)
  }

  mouseClick (event: MouseEvent): void {
    this.addRandom(event.y)
    this.addRandom(event.button)
    this.addRandom(event.x)
  }

  keyUp (event: KeyboardEvent): void {
    this.addRandom(event.code)
  }

  deviceMotion (event: DeviceMotionEvent): void {
    if (event.rotationRate) {
      this.addRandom(event.rotationRate.alpha)
      this.addRandom(event.rotationRate.gamma)
      this.addRandom(event.rotationRate.beta)
    }

    if (event.acceleration) {
      this.addRandom(event.acceleration.y)
      this.addRandom(event.acceleration.z)
      this.addRandom(event.acceleration.x)
    }
  }

  resize (): void {
    this.addRandom(window.innerWidth)
    this.addRandom(window.innerHeight)
  }

  /// util

  // https://codepen.io/jsstrn/pen/mMMmZB
  getFontSize (textLength: number): string {
    const baseSize = 9
    if (textLength >= baseSize) {
      textLength = baseSize - 2
    }
    const fontSize = baseSize - textLength
    return `${fontSize}vw`
  }
}
